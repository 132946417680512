
/*header*/
.header_container{
  width: 100%;
  height: 10vh;
  background-color: #926123;
  position: fixed;
}

.header_container img{
  width: 80px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}


/*Login*/

.loginContainer{
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
}

.coverLogin{
  width: 50%;
  background-image: url("http://puertadehierroac.mx/imagenes/galeria/14.JPG");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.loginForm{

  width:50%;
  height: 100%;
  background-color: ;
  padding: 0%;
  left: 55%;
  top:10%;

}

.inputsContainer{
  padding: 0%;
  border-radius: 10px;
  border: #926123 solid 3px;
  width: 90%;
  height: 90%;
  margin-top: 15px;
  margin-left: 0%;
  background-color: grey;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.loginForm img{
  width: 30%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.loginForm h1{
  font-family: "Gotham-Ultra";
  color: white;
  margin-bottom: -3%;
  text-align: center;
}


.loginForm h2{
  font-family: "Gotham";
  color: white;
  text-align: center;
}

.loginForm input{
  width:70%;
  margin-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  font-size: 3vh;
  border:1.5px #926123 solid;
  border-radius: 5px;
  outline: none;
  font-family: "Gotham-Book";
  color:#6D356C;
}

.loginForm button{
  color:white;
  background-color: #926123;
  outline: none;
  border: none;
  margin-top: 5%;
  width:200px;
  height: 60px;
  cursor: pointer;
  border-radius: 10px;
  font-size: 20px;
  font-family: "Gotham";
  opacity: 0.8;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.loginForm button:hover{
  background-color: #70542e;
  opacity: 1;

}



/*Landing*/

.landingContainer{
  width: 100%;
  padding: 1%;

  padding-top: 11vh;
}


.landingContenidoContainer{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 .5rem 3rem #926123;
  text-align: center;
  padding: 1%;
}

.landingContenidoContainer h1{
  color: black;
  text-align: center;
}

.landingContenidoContainer button{
  width: 45vh;
  height: 15vh;
  font-size: large;
  color: white;
  background-color: #6d6e6e;
  margin: 0.5%;
  outline: none;
  border: none;
  border-radius: 5%;
}

.landingContenidoContainer button:hover{
  background-color: #939494;
  cursor: pointer;
}

.landingCuentasContainer{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 .5rem 3rem #926123;
  text-align: center;
  padding: 1%;
  margin-top: 5%;
}

.landingCuentasContainer h1{
  color: black;
  text-align: center;
}

.landingCuentasContainer button{
  width: 45vh;
  height: 15vh;
  font-size: large;
  color: white;
  background-color: #6d6e6e;
  margin: 0.5%;
  outline: none;
  border: none;
  border-radius: 5%;
}

.landingCuentasContainer button:hover{
  background-color: #939494;
  cursor: pointer;
}


/*Avances*/

.introduccion-container{
  padding-top: 11vh;
  text-align: center;
}

.avances_container{
  width: 100%;
  padding: 1%;
  padding-top: 3vh;
  display: flex;
}

.form_avances{
  width: 45%;
  height: auto;
  box-shadow: 0 .5rem 3rem #926123;
  padding: 10px;
}

.imagesPreview_container{
  width: 100%;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
}

.imagesPreview_container img{
  width: 200px;
  height: 200px;
  margin: auto;
  object-fit:cover
}













.fileDropArea_uploadExcel {
  position: relative;
  display: block;
  align-items: center;
  text-align: center;
  width: 50%;
  max-width: 100%;
  padding: 25px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  border: 2px dashed grey;
  border-radius: 3px;
  transition: 0.2s;
}

.fileDropArea_uploadExcel img{
  width: 20%;
  margin-top: 1%;
  margin-bottom: 1%;
}

.fileDropArea_uploadExcel h3{
  color: #926123;
  font-size: 1.5rem;
  font-weight: bolder;
}

.fileDropArea_uploadExcel h5{
  font-weight: 100;
  font-size: 1.3rem;
  padding-top: 0.5px;
}

.file-input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;

}

.eliminarAvances_container{
  background-color: grey;
}


.obras-container{
  width: 100%;
  height: 95vh;
  display:block;
  position: absolute;
  overflow-y:scroll;
  background-color: grey;
}

.card-obras-container{
  width:60%;
  border: solid 2px #926123;
  border-radius: 10px;
  margin: 1%;
  padding: 1%;
  background-color: #adadad;
}

.card-obras-container h1{
  font-family:sans-serif;
}

.card-obras-container p{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}

.cardsRow-container{
  display: flex
}

.d-block.w-100{
  height: 280px!important;
}


















.previewCard_avances{
  width: 45%;
  height: auto;
  box-shadow: 0 .5rem 3rem #926123;
  padding: 10px;
  margin-left: 5%;
  
}

.cardImage{
  width: 200px;
  height: 200px;
  margin: auto;
  object-fit:cover
}

.actionButtonsAvances_container{
  width: 100%;
  text-align: center;
  margin-top: 80px;
}

.actionButtonsAvances_container button{
  width: 30%;
  height: 50px;
  margin: 10px;
  outline: none;
  border: none;
  border-radius: 10px;
}

#publicarButton{
  background-color: #926123;
  color: white;
  font-weight: bolder;
}

#cancelarButton{
  background-color: #939494;
  color: white;
  font-weight: bolder;
}

/*directorio telefonico*/

.directorioTelefonico_container{
  padding: 1%;
  padding-top: 11vh;
}


.formAndPreview_container{
 width: 100%;
  display: flex;
}


.formDirectorio_container{
  width: 47%;
  height: auto;
  box-shadow: 0 .5rem 3rem #926123;
  margin: 1%;
  padding:1%;
}

.previewDirectorio_container{
  width: 47%;
  height: auto;
  box-shadow: 0 .5rem 3rem #926123;
  margin: 1%;
  padding: 1%;
}



.modificarCuenta_container{
  padding: 1%;
  padding-top: 11vh;
  margin-left: 40vh;
  margin-right: 40vh;
  background-color: #939494;
  text-align: center
}

.busquedaResidente{
  background-color: ;
}

.busquedaResidente input{
  height: 30px;
  border-radius: 5px;
  border: none;
}

.busquedaResidente button{
  border: none;
  outline: none;
  border-radius: 5px;
  background-color: #926123;
  font-size: 20px;
  font-weight: bolder;
  color: white;
}

.botonesModificar input{
  height: 40px;
  border-radius: 5px;
  border: none;
}

.botonesModificar button{
  border: none;
  outline: none;
  border-radius: 5px;
  background-color: #926123;
  font-size: 15px;
  font-weight: bolder;
  color: white;
  height: 40px;
}